<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p(v-html="exp.expDesc")
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import BackHelp from "../../../components/BackHelp";
import Footer from "../../../components/TheFooter";
export default {
  name: "AdvertisementTool",
  components: { Footer, BackHelp, Navbar },
  data() {
    return {
      searchText: "",
      ContactList: [
        {
          title: "7.1 How to set up ads",
          description: [
            {
              text: "Promoting your business is never easy like before. With our automated ads system, you don’t have to be a marketing expert to set up ads on social media. We make it straight forward for you. All you need is just fill up a few important entries, Kloser will settle the rest.",
              imgType: false,
            },
            {
              text: "1. Click the “Start up ads”",
              imgType: true,
              img: "600.Dashboard -website – empty–ads.png",
            },
            {
              text: "2. Our system will auto pick up to 6 images from your listing to promo in social media, click “Next” once you review the ads setting",
              imgType: true,
              img: "603. Automate ads-preview ads – 8.png",
            },
          ],
          explanation: [
            {
              expTitle: "Edit ad images : ",
              expDesc:
                "If you are not satisfied with the images, click “Edit Ad images” and choose other images from your listing. Click “Save” once it done",
              imgType: true,
              img: "edit-image.png",
            },
            {
              expTitle: "Ad text : ",
              expDesc:
                "Tap on the text box in order to enter a short description for the ads",
              imgType: false,
            },
            {
              expTitle: "",
              expDesc: "3. Choose an ad package and Click “Pay now”",
              imgType: true,
              img: "605. Automate ads-define plan – 4.png",
            },
            {
              expTitle: "",
              expDesc:
                "4. You can pause the ad package anytime, just click the “Pause ad” and it will pop up a confirmation message and click “Pause ad”",
              imgType: true,
              img: "520.Dashboard-pause-1.png",
            },
            {
              expTitle: "",
              expDesc:
                "5. If you decide to continue the ad package are expire soon, click the “Top up”, select the top up method and click “Next”. Choose an ad package for it and click “Pay now”",
              imgType: true,
              img: "513. Top up FB ads balance-2.png",
            },
          ],
        },
      ],
    };
  },
};
</script>
